import variables from '@styles/variables';

const ACTIVE_LABEL_TRANSLATE_Y = 4;
const ACTIVE_LABEL_SCALE = 0.8668;

const INACTIVE_LABEL_TRANSLATE_Y = variables.INACTIVE_LABEL_TRANSLATE_Y;
const INACTIVE_LABEL_SCALE = 1;

const LABEL_ANIMATION_DURATION = 80;

export {ACTIVE_LABEL_TRANSLATE_Y, ACTIVE_LABEL_SCALE, INACTIVE_LABEL_TRANSLATE_Y, INACTIVE_LABEL_SCALE, LABEL_ANIMATION_DURATION};
