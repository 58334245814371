import type {HeaderGapProps, HeaderGapReturnType} from './types';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function HeaderGap({styles}: HeaderGapProps): HeaderGapReturnType {
    return null;
}

HeaderGap.displayName = 'HeaderGap';

export default HeaderGap;
